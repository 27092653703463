import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  date?: string
  file?: string
  structure?: string | null
  subtitle?: string
  thumbnail?: ImageProps
  type?: string | null
  title?: string
  URL?: string
}

export const Article = memo(function Article({
  className,
  file,
  subtitle,
  thumbnail,
  title,
  URL,
}: Props) {
  return (
    <Container
      className={className}
      href={URL || file || undefined}
      rel="noopener"
      target="_blank"
    >
      <Background>
        {thumbnail ? (
          <LazyLoadComponent>
            <Image {...thumbnail} />
          </LazyLoadComponent>
        ) : null}
      </Background>

      {title ? <Title>{title}</Title> : null}
      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
    </Container>
  )
})

const Container = styled.a`
  display: none;
  width: calc(33.333% - 1.625rem);
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  margin: 5rem 1.625rem 0 0;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  &.visible {
    display: block;
  }

  @media (max-width: 991px) {
    width: calc(50% - 1.625rem);
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  margin-bottom: 2rem;
  padding-bottom: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 767px) {
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-size: 1.125rem;
  line-height: 2rem;
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 2rem;
  text-transform: uppercase;
`
