import styled from '@emotion/styled'
import { Select } from 'app/components/Common/Select'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { compact, uniqBy } from 'lodash'
import React, { memo, useState } from 'react'

import { Article, Props as ArticleProps } from './Article'

export interface Props {
  articles: ArticleProps[]
  languageCode: string
}

export const Articles = memo(function Articles({
  articles,
  languageCode,
}: Props) {
  if (articles.length < 1) {
    return null
  }

  const [activeStructure, setActiveStructure] = useState('')
  const [activeType, setActiveType] = useState('')
  const [activeMonth, setActiveMonth] = useState('')
  const [activeYear, setActiveYear] = useState('')

  const structure = compact(
    articles
      .sort((a: any, b: any) => a.structure?.localeCompare(b.structure))
      .map((e) =>
        e.structure
          ? {
              ['value']: e.structure,
            }
          : null,
      ),
  )

  const type = compact(
    articles
      .sort((a: any, b: any) => a.structure?.localeCompare(b.structure))
      .map((e) =>
        e.type
          ? {
              ['value']: useVocabularyData(
                e.type.toLocaleLowerCase().replace(' ', '-'),
                languageCode,
              ),
            }
          : null,
      ),
  )

  const month = compact(
    articles
      .sort(
        (a: any, b: any) =>
          new Date(a.date).getMonth() - new Date(b.date).getMonth(),
      )
      .map((e) =>
        e.date
          ? {
              ['value']: new Date(e.date).toLocaleDateString(languageCode, {
                month: 'long',
              }),
            }
          : null,
      ),
  )

  const year = compact(
    articles
      .sort(
        (a: any, b: any) =>
          new Date(a.date).getFullYear() - new Date(b.date).getFullYear(),
      )
      .map((e) =>
        e.date
          ? {
              ['value']: new Date(e.date).toLocaleDateString(languageCode, {
                year: 'numeric',
              }),
            }
          : null,
      ),
  )

  return (
    <Container>
      <Filters row wrap>
        <Select
          options={[
            {
              value: '',
              label: useVocabularyData('structure', languageCode),
            },
            ...uniqBy(structure, 'value'),
          ]}
          onChange={(e) => setActiveStructure(e.target.value)}
          name="structure"
        />
        <Select
          options={[
            {
              value: '',
              label: useVocabularyData('type', languageCode),
            },
            ...uniqBy(type, 'value'),
          ]}
          onChange={(e) => setActiveType(e.target.value)}
          name="type"
        />
        <Select
          className="month"
          options={[
            {
              value: '',
              label: useVocabularyData('month', languageCode),
            },
            ...uniqBy(month, 'value'),
          ]}
          onChange={(e) => setActiveMonth(e.target.value)}
          name="month"
        />
        <Select
          options={[
            {
              value: '',
              label: useVocabularyData('year', languageCode),
            },
            ...uniqBy(year, 'value'),
          ]}
          onChange={(e) => setActiveYear(e.target.value)}
          name="year"
        />
      </Filters>

      <List row wrap>
        {articles
          .sort((a, b) =>
            (a.date ? a.date : 1) > (b.date ? b.date : 0) ? -1 : 1,
          )
          .map((item: any, index) => (
            <Article
              className={
                (activeStructure === item.structure ||
                  activeStructure === '') &&
                (activeType === item.type || activeType === '') &&
                (activeMonth ===
                  new Date(item.date).toLocaleDateString(languageCode, {
                    month: 'long',
                  }) ||
                  activeMonth === '') &&
                (activeYear ===
                  new Date(item.date).toLocaleDateString(languageCode, {
                    year: 'numeric',
                  }) ||
                  activeYear === '')
                  ? 'visible'
                  : undefined
              }
              key={index}
              {...item}
            />
          ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  margin: 6.25rem 0 12.5rem;
  padding: 0 11.944vw;

  @media (max-width: 1023px) {
    margin: 5rem 0 9.375rem;
  }
  @media (max-width: 767px) {
    margin: 5rem 0 6.25rem;
  }

  @media (max-width: 574px) {
    padding: 0 1.875rem;
  }
`

const Filters = styled(FlexBox)`
  margin: 0 -1.5rem -1rem 0;

  select {
    width: calc(25% - 1.5rem);
    margin-right: 1.5rem;
    &.month {
      text-transform: capitalize;
    }
  }

  @media (max-width: 991px) {
    margin: 0 auto;
    select {
      width: 100%;
      margin-right: 0;
      margin-top: 0.375rem;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`

const List = styled(FlexBox)`
  margin-right: -1.625rem;

  @media (max-width: 767px) {
    margin-right: 0;
  }
`
